define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-banner", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "float-kit/components/d-tooltip", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _dTooltip, _activityPubFollowBtn, _activityPubHandle, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubBanner extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get bannerDescription() {
      const visibility = this.args.actor.default_visibility;
      const publicationType = this.args.actor.publication_type;
      return _discourseI18n.default.t(`discourse_activity_pub.banner.${visibility}_${publicationType}`);
    }
    get responsiveText() {
      return _discourseI18n.default.t("discourse_activity_pub.banner.responsive_text");
    }
    get desktopText() {
      return _discourseI18n.default.t("discourse_activity_pub.banner.text", {
        model_name: this.args.actor.model.name
      });
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="activity-pub-banner">
          {{#if @actor}}
            <div class="activity-pub-banner-left activity-pub-banner-side">
              <DTooltip
                @icon="discourse-activity-pub"
                @content={{this.bannerDescription}}
              />
              <div class="activity-pub-banner-text">
                <span class="desktop">{{this.desktopText}}</span>
                <span class="responsive">{{this.responsiveText}}</span>
              </div>
            </div>
            <div class="activity-pub-banner-right activity-pub-banner-side">
              {{#unless this.site.mobileView}}
                <ActivityPubHandle @actor={{@actor}} />
              {{/unless}}
              <ActivityPubFollowBtn @actor={{@actor}} @type="follow" />
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "kuP3hUXw",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-banner\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[10,0],[14,0,\"activity-pub-banner-left activity-pub-banner-side\"],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@icon\",\"@content\"],[\"discourse-activity-pub\",[30,0,[\"bannerDescription\"]]]],null],[1,\"\\n          \"],[10,0],[14,0,\"activity-pub-banner-text\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"desktop\"],[12],[1,[30,0,[\"desktopText\"]]],[13],[1,\"\\n            \"],[10,1],[14,0,\"responsive\"],[12],[1,[30,0,[\"responsiveText\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"activity-pub-banner-right activity-pub-banner-side\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"site\",\"mobileView\"]]],[[[1,\"            \"],[8,[32,1],null,[[\"@actor\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[8,[32,2],null,[[\"@actor\",\"@type\"],[[30,1],\"follow\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@actor\"],false,[\"if\",\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-banner.js",
      "scope": () => [_dTooltip.default, _activityPubHandle.default, _activityPubFollowBtn.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubBanner;
});